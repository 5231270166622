import '../sass/app.scss';
import './calendly-links';
import './header-fixed';
import './review-slider';
import './contact';
import './mobile-nav';
import './faq';
import './quote-slider';

console.info('[Up2Rent theme is live]');

let lastScrollTop = 0; // Keep track of the last scroll position
let ticking = false; // To ensure we don't call rAF more than once per frame

window.addEventListener('scroll', function () {
  const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;

  // Only update scrolling state if we're not already waiting on a rAF callback
  if (!ticking) {
    window.requestAnimationFrame(function () {
      const header = document.querySelector('.header');

      if (currentScrollPos > lastScrollTop && window.scrollY > (window.innerHeight / 2)) { // More than 50% scrolled
        header.classList.add('header-fixed');
      } else if (currentScrollPos === 0) { // Scrolled to the top
        header.classList.remove('header-fixed');
      }

      lastScrollTop = currentScrollPos <= 0 ? 0 : currentScrollPos; // For Mobile or negative scrolling

      // Reset the ticking flag so we can update the scroll position on the next scroll
      ticking = false;
    });

    // Set a flag to indicate we've already requested an animation frame
    ticking = true;
  }
});

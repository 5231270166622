import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';

// init Swiper:
const swiper = new Swiper('.reviews', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  spaceBetween: 20,
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  // Custom class names
  slideClass: 'review',
  wrapperClass: 'reviews-wrapper',
  modules: [Pagination],
});

// Function to enable or disable swiping based on viewport width
function updateSwiperTouchMove() {
  // Check if viewport width is greater than 768 pixels
  if (window.matchMedia("(min-width: 768px)").matches) {
    // If yes, disable swiping
    swiper.allowTouchMove = false;
  } else {
    // If not, enable swiping
    swiper.allowTouchMove = true;
  }
}

// Call the function on initial load
updateSwiperTouchMove();

// Add event listener for window resize
window.addEventListener('resize', function () {
  updateSwiperTouchMove();
});


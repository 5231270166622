import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

document.addEventListener('DOMContentLoaded', () => {
  const quotesElement = document.querySelector('.quotes');
  if (quotesElement) {
    const swiper2 = new Swiper('.quotes', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      spaceBetween: 20,
      slideClass: 'client-quote_content',
      wrapperClass: 'quotes-wrapper',
      slidesPerView: 1,
      modules: [Navigation],
      navigation: {
        prevEl: '.quotes_previous',
        nextEl: '.quotes_next',
      },
    });
  } else {
    console.error("Element with class '.quotes' not found.");
  }
});
const validateInput = ({ pattern }, event) => {
  const content = event.target.value;
  const errorDiv = event.target.parentNode.querySelector('.contact_error');
  if (pattern.test(content)) {
    errorDiv.style.visibility = 'hidden';
    event.target.isValid = true;
    return true;
  }
  else {
    errorDiv.style.visibility = 'visible';
    event.target.isValid = false;
    return false
  }
}
window.validateInput = validateInput;

const sendContactForm = async (event) => {
  event.preventDefault();

  const form = event.target;

  form.classList.add('contact_form-disabled');

  // Find all input and textarea elements within the form
  const fields = form.querySelectorAll('input, textarea');
  const formData = {};

  const validatedFields = Array.from(fields).map((el, i) => {
    fields[i].focus();
    formData[fields[i].name] = fields[i].value;
    return !fields[i].isValid && fields[i].name;
  }).filter(v => v);

  if (validatedFields.length > 0) {
    console.log('Invalid fields', validatedFields);
    form.classList.remove('contact_form-disabled');
    return;
  }
  const gRecaptchaResponse = await grecaptcha.execute('6LcMPb4pAAAAAAQ_kSJ6bvDoYUtHk7uyHUWVJT4j', { action: 'submit' });
  console.log('gRecaptchaResponse: ', gRecaptchaResponse);

  //fields are valid
  const res = await fetch('/wp-json/u2r/v2/contact/', {
    method: 'POST',   // Specify the method
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ gRecaptchaResponse, ...formData })    // Attach the JSON data as the body of the request
  });
  try {
    const json = await res.json();
    console.log('json: ', json);
    if (res.ok) {
      document.querySelector('#formContainer').style.display = "none";
      document.querySelector('#formConfirmation').style.display = "block";
    }
  }
  catch (e) { }
  form.classList.remove('contact_form-disabled');
}
window.sendContactForm = sendContactForm;